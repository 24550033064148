<template>
<a-modal :title="title" max-width="1040px" width="100%" v-model:visible="visible" :footer="false" :maskClosable="false" @cancel="closeModal()">
    <a-row :gutter="24">
        <a-col :span="24">
            <a-steps v-model:current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
            <div class="steps-content" v-if="steps[current].title == 'Providers'">
                <a-form :model="providerForm" autocomplete="off" :scrollToFirstError="true" layout="vertical" @finish="submitProviderForm">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.providerName')" name="name" :rules="[{ required: true, message: $t('providers.providerName')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerForm.name" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.providerAddress')" name="address" :rules="[{ required: true, message: $t('providers.providerAddress')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerForm.address" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('global.country')" name="countryId" :rules="[{ required: true, message: $t('global.country')+' '+$t('global.validation') }]">
                                    <GlobalCodeDropDown  v-model:value="providerForm.countryId" :dataId="20" @handleGlobalChange="handleGlobalChange($event,'providerForm.countryId')" @change="checkChangeInput()" />
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.country?errorMsg.country[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('global.state')" name="stateId" :rules="[{ required: true, message: $t('global.state')+' '+$t('global.validation') }]">
                                    <GlobalCodeDropDown v-model:value="providerForm.stateId" :dataId="21" @handleGlobalChange="handleGlobalChange($event,'providerForm.stateId')" @change="checkChangeInput()" />
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.state?errorMsg.state[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.city')" name="city" :rules="[{ required: true, message: $t('providers.city')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerForm.city" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.zipcode')" name="zipcode" :rules="[{ required: true, message:$t('global.validValidation')+' '+ $t('global.zipcode').toLowerCase(),pattern: regex.zipCode }]">
                                    <a-input v-model:value="providerForm.zipcode" size="large" @change="checkChangeInput()" />
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.zipCode?errorMsg.zipCode[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.phoneNumber')" name="phoneNumber" :rules="[{ required: true, message: $t('global.validValidation')+' '+$t('global.phoneNo').toLowerCase(),pattern:regex.phoneNumber}]">
                                    <a-input v-maska="'###-###-####'" @change="checkChangeInput()"  v-model:value="providerForm.phoneNumber" placeholder="Please enter 10 digit number" size="large"  style="width: 100%" />
                                    <!-- <PhoneNumber @change="checkChangeInput()" v-model:value.trim="providerForm.phoneNumber" @setPhoneNumber="setPhoneNumberProviderForm"/> -->
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.phoneNumber?errorMsg.phoneNumber[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.tags')" name="tagId" :rules="[{ required: true, message: $t('providers.tags')+' '+$t('global.validation') }]">
                                    <GlobalCodeDropDown v-model:value="providerForm.tagId" mode="multiple" :dataId="12" @handleGlobalChange="handleGlobalChange($event,'providerForm.tagId')" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.modules')" name="moduleId" :rules="[{ required: true, message: $t('providers.modules')+' '+$t('global.validation') }]">
                                    <GlobalCodeDropDown :listHeight="100" v-model:value="providerForm.moduleId" mode="multiple"  :dataId="48" @handleGlobalChange="handleGlobalChange($event,'providerForm.moduleId')" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :sm="12" :xs="24">
                            <div class="form-group">
                                <label>Active/Inactive</label>
                                <a-switch v-model:checked="providerForm.isActive" @change="UpdateStatus($event)" />
                            </div>
                        </a-col>
                        <a-col :span="24">
                            <div class="steps-action">
                                <a-button v-if="current > 0" style="margin-right: 8px" @click="prev">{{$t('global.previous')}}</a-button>
                                <a-button v-if="current < steps.length - 1" type="primary" html-type="submit">{{$t('global.next')}}</a-button>
                                <a-button v-if="current == steps.length - 1" type="primary" @click="$message.success('Processing complete!')">
                                    {{$t('global.done')}}
                                </a-button>
                            </div>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <div class="steps-content" v-if="steps[current].title == 'Locations'">
                <a-form :model="providerLocationForm" ref="clearLocationForm" autocomplete="off" :scrollToFirstError="true" layout="vertical" @finish="submitProviderLocationForm">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.locationName')" name="locationName" :rules="[{ required: true, message: $t('providers.locationName')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerLocationForm.locationName" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.noOfLocations')" name="numberOfLocations" :rules="[{ required: true, message: $t('providers.noOfLocations')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerLocationForm.numberOfLocations" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.locationAddress')" name="address" :rules="[{ required: true, message: $t('providers.locationAddress')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerLocationForm.address" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('global.state')" name="stateId" :rules="[{ required: true, message: $t('global.state')+' '+$t('global.validation') }]">
                                    <GlobalCodeDropDown v-model:value="providerLocationForm.stateId" :dataId="21" @handleGlobalChange="handleGlobalChange($event,'providerLocationForm.stateId')" @change="checkChangeInput()" />
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.state?errorMsg.stateId[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.city')" name="city" :rules="[{ required: true, message: $t('providers.city')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerLocationForm.city" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.zipcode')" name="zipCode" :rules="[{ required: true, message:$t('global.validValidation')+' '+ $t('global.zipcode').toLowerCase(),pattern: regex.zipCode }]">
                                    <a-input v-model:value="providerLocationForm.zipCode" size="large" @change="checkChangeInput()" />
                                    <ErrorMessage v-if="errorMsg" :name="errorMsg.zipCode?errorMsg.zipCode[0]:''" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.phoneNumber')" name="phoneNumber" :rules="[{ required: true, message: $t('global.validValidation')+' '+$t('global.phoneNo').toLowerCase(),pattern:regex.phoneNumber}]">
                                    <!-- <a-input-number size="large" maxlength="10" @change="checkChangeInput()" style="width: 100%"/> -->
                                    <a-input v-maska="'###-###-####'" @change="checkChangeInput()"  v-model:value="providerLocationForm.phoneNumber"  placeholder="Please enter 10 digit number" size="large"  style="width: 100%" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.emailAddress')" name="email" :rules="[{ required: true, message: $t('global.validValidation')+' '+$t('global.email').toLowerCase(), type: 'email' }]">
                                    <a-input v-model:value="providerLocationForm.email" placeholder="test@test.com" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :md="8" :sm="12" :xs="24">
                            <div class="form-group">
                                <a-form-item :label="$t('providers.website')" name="websiteUrl" :rules="[{ required: true, message: $t('providers.website')+' '+$t('global.validation')  }]">
                                    <a-input v-model:value="providerLocationForm.websiteUrl" size="large" @change="checkChangeInput()" />
                                </a-form-item>
                            </div>
                        </a-col>
                        <a-col :span="24" v-if="arrayToObjact(screensPermissions,32) && !editProviderLocationData">
                            <a-button class="btn primaryBtn" html-type="submit">{{$t('global.add')}}</a-button>
                        </a-col>
                        <a-col :span="24" v-if="arrayToObjact(screensPermissions,33) && editProviderLocationData">
                            <a-button class="btn primaryBtn" html-type="submit">{{$t('global.update')}}</a-button>
                        </a-col>
                        <a-col :span="24">
                            <a-table class="tableCommonSpace" v-if="providerLocationlistData && arrayToObjact(screensPermissions,31) " :columns="columns" :data-source="providerLocationlistData" :pagination="false" :scroll="{ x: 900 }">
                                <template #action="text" >
                                  <a-tooltip placement="bottom" v-if="arrayToObjact(screensPermissions,33)">
                                        <a class="icons" @click="editProviderLocation(text.record.id)">
                                            <EditOutlined />
                                        </a>
                                    </a-tooltip>
                                    <a-tooltip placement="bottom" v-if="arrayToObjact(screensPermissions,34)">
                                        <a class="icons" @click="deleteProviderLocation(text.record.id)">
                                            <DeleteOutlined />
                                        </a>
                                    </a-tooltip>
                                </template>
                            </a-table>
                            <!-- <Loader /> -->
                        </a-col>
                        <a-col :span="24">
                            <div class="steps-action">
                                <a-button v-if="current > 0" style="margin-right: 8px" @click="prev">{{$t('global.previous')}}</a-button>
                                <a-button v-if="current < steps.length - 1" type="primary" html-type="submit">{{$t('global.next')}}</a-button>
                                <a-button v-if="current >0" type="primary" @click="saveModal()">
                                    {{$t('global.save')}}
                                </a-button>
                            </div>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </a-col>
    </a-row>
    <Loader/>
</a-modal>
</template>
<script>
import { ref, computed, reactive, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import Loader from "@/components/loader/Loader";
import {
  warningSwal,
  successSwal,
  arrayToObjact,
  errorSwal
} from "@/commonMethods/commonMethod";
import { messages } from "@/config/messages";
import { DeleteOutlined,EditOutlined } from "@ant-design/icons-vue";
import { regex } from "@/RegularExpressions/regex";
import GlobalCodeDropDown from "@/components/modals/search/GlobalCodeSearch.vue";
// import PhoneNumber from "@/components/modals/forms/fields/PhoneNumber"

export default {
  components: {
    DeleteOutlined,
    EditOutlined,
    Loader,
    GlobalCodeDropDown,
    // PhoneNumber
  },
  props: {
    isAdd: {
      type: Boolean,
    },
    providerId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    // const current = ref(0);
    const visible = ref(true);
    const store = useStore();
    const clearLocationForm =ref()
    const title = props.isAdd ? "Edit Providers" : "Add Providers";
    const providerId = reactive(props.providerId);

    const providerForm = reactive({
      name: "",
      address: "",
      countryId: 19,
      stateId: "",
      city: "",
      zipcode: "",
      phoneNumber: "",
      tagId: ref([]),
      moduleId: ref([]),
      isActive: true,
    });

    const providerLocationForm = reactive({
      locationName: "",
      numberOfLocations: "",
      address: "",
      stateId: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
      email: "",
      websiteUrl: "",
      isActive: 1,
      noOfLocations:''
    });

    const current = computed({
      get: () => store.state.provider.counter,
      set: (value) => {
        if ((providersData.value.provider||providersData.value.updateSingleProvider) && !value ==1) {
          store.state.provider.counter = value;
          console.log('value',store.state.provider.counter)
        } else {
          if (Object.values(providerForm).filter((item) => item != "").length >= 9 ) {
            submitProviderForm();
          } else {
            errorSwal(messages.fieldsRequired);
            store.state.provider.counter = 0;
          }
        }
      },
    });

    const providersData = computed(() => {
      return store.state.provider;
    })

    function UpdateStatus(event) {
      providerForm.isActive = event;
    }
    const submitProviderForm = () => {
      console.log('providersData',providersData)
      providerForm.phoneNumber = providerForm.phoneNumber.replace(/-/g,'')
      if (providersData.value.provider == null && !providerId) {
        store.dispatch("provider", providerForm).then((resp)=>{
          if(resp==true){
            store.state.provider.providersListAll = "";
            store.dispatch("providersListAll");
          }
        })
      } else {
        store.dispatch("updateSingleProvider", {
          data:providerForm,
          id: providerId?providerId:providersData.value.provider.id,
        }).then((resp)=>{
          if(resp==true){
            store.state.provider.providersListAll = "";
            store.dispatch("providersListAll");
          }
        })
      }
      

    };

    const providerLocationlistData = computed(() => {
      return store.state.provider.providerLocationList;
    })

    

    const columns = computed(() => {
      return store.state.provider.columns;
    })


    const editSingleProvider = computed(() => {
      return store.state.provider.editSingleProvider;
    })

    const editProviderLocationData = computed(() => {
      return store.state.provider.editProviderLocation;
    })

    const submitProviderLocationForm = () => {
      let proId = providerId ? providerId : providersData.value.provider.id;
      if(editProviderLocationData.value){
        providerLocationForm.noOfLocations =providerLocationForm.numberOfLocations
        store.dispatch("updateProviderLocation", {
          data:providerLocationForm,
          id: proId,
          locationId:editProviderLocationData.value.id
        }).then((response)=>{
          if(response == true)
          clearLocationForm.value.resetFields()
          store.commit('editProviderLocation', null)
          store.state.provider.providersListAll = "";
          store.dispatch("providersListAll");
          store.dispatch("providerLocationList", proId);
        });
        
      }else{
        store.dispatch("providerLocation", {
          data:providerLocationForm,
          id: proId,
        }).then((response)=>{
          if(response == true)
          clearLocationForm.value.resetFields()
          store.state.provider.providersListAll = "";
          store.dispatch("providersListAll");
          store.dispatch("providerLocationList", proId);
        });
      }
      
    };

    const next = () => {
            store.commit("providerCounterPlus");
        };
        const prev = () => {
            store.commit("providerCounterMinus");
        };

    const globalCode = computed(() => {
      return store.state.common;
    });

    const providers = store.getters.providersRecords.value;

    const providerTags = computed(() => {
      return store.state.common.documentTags;
    });

    const providerModules = computed(() => {
      return store.state.common.modules;
    });

    const form = reactive({
      ...providerLocationForm,
    });

    function saveModal() {
      successSwal(messages.formSuccess);
      Object.assign(providerLocationForm, form);
      Object.assign(providerForm, form);
      //store.dispatch("provider");
      store.commit("providerResetCounter");
      emit("closeModal", false);
      store.commit('editProviderLocation', null)
      store.commit("checkChangeInput", false);
      providersData.value.provider=null
    }

    function checkChangeInput() {
      store.commit("checkChangeInput", true);
    }

    const checkFieldsData = computed(() => {
      return store.state.common.checkChangeInput;
    });

    function closeModal() {
      emit("closeModal", true)
      if (checkFieldsData.value) {
        warningSwal(messages.modalWarning).then((response) => {
          if (response == true) {
            emit("closeModal", false);
            Object.assign(providerForm, form);
            Object.assign(providerLocationForm, form);
            store.commit("providerResetCounter");
            store.commit("checkChangeInput", false);
            store.state.provider.provider = null;
            store.commit('editProviderLocation', null)
          } else {
            emit("closeModal", true);
          }
        });
      } else {
        emit("closeModal", false);
      }
    }

    function editProviderLocation(id) {
      store.commit("loadingStatus", true);
      if (providerId != null) {
            store
              .dispatch("editProviderLocation", {
                id: providerId,
                locationId: id,
              })
      } else {
            store.dispatch("editProviderLocation", {
              id: providersData.value.provider.id,
              locationId: id,
            });
      }
    }

    function deleteProviderLocation(id) {
      
      if (providerId != null) {
        warningSwal(messages.deleteWarning).then((response) => {
          if (response == true) {
            store.state.provider.editProviderLocation=''
            Object.assign(providerLocationForm, form)
            store.commit("loadingStatus", true);
            store
              .dispatch("deleteProviderLocation", {
                id: providerId,
                locationId: id,
              })
              .then(() => {
                store.dispatch("providerLocationList", providerId);
              });
          }
        });
      } else {
        warningSwal(messages.deleteWarning).then((response) => {
          if (response == true) {
            store.state.provider.editProviderLocation=''
            Object.assign(providerLocationForm, form)
            store.commit("loadingStatus", true);
            store.dispatch("deleteProviderLocation", {
              id: providersData.value.provider.id,
              locationId: id,
            });
            setTimeout(() => {
              store.dispatch(
                "providerLocationList",
                providersData.value.provider.id
              );
            }, 2000);
          }
        });
      }
    }
    onMounted(()=>{
      store.state.provider.counter = 0
      store.state.provider.providerLocationList = null
    })
    watchEffect(() => {
      //store.state.provider.counter = 0;
      if (providerId != null) {
        if (editSingleProvider.value) {
          store.commit("loadingStatus", true);
          Object.assign(providerForm, editSingleProvider.value);
          Object.assign(providerLocationForm, editProviderLocationData.value);
          providerLocationForm.numberOfLocations = editProviderLocationData.value?.noOfLocations
          store.dispatch("providerLocationList", providerId);
          setTimeout(() => {
            store.commit("loadingStatus", false);
          }, 2000);
        }
      }
    });

    const handleGlobalChange = (data, type) => {
      if (type == "providerForm.countryId") {
        providerForm.countryId = data;
      }
      if (type == "providerForm.stateId") {
        providerForm.stateId = data;
      }
      if (type == "providerForm.tagId") {
        providerForm.tagId = data;
      }
      if (type == "providerForm.moduleId") {
        providerForm.moduleId = data;
      }
      if (type == "providerLocationForm.stateId") {
        providerLocationForm.stateId = data;
      }
    };

    return {
      handleGlobalChange,
      clearLocationForm,
      editProviderLocationData,
      editProviderLocation,
      screensPermissions: store.getters.screensPermissions,
      arrayToObjact,
      // setPhoneNumberProviderForm,
      // setPhoneNumberProviderLocationForm,
      checkChangeInput,
      checkFieldsData,
      UpdateStatus,
      regex,
      current,
      visible,
      providerForm,
      editSingleProvider,
      providerLocationForm,
      submitProviderForm,
      submitProviderLocationForm,
      next,
      prev,
      saveModal,
      closeModal,
      props,
      title,
      emit,
      columns,
      deleteProviderLocation,
      providers,
      globalCode,
      providerTags,
      providerModules,
      providerLocationlistData,
      steps: [
        {
          title: "Providers",
          content: "First-content",
        },
        {
          title: "Locations",
          content: "Second-content",
        },
      ],

      bindProps: store.state.common.bindProps,
    };
  },
};
</script>

<style scoped>
.steps-content {
  margin-top: 16px;
  border-radius: 6px;
  min-height: 200px;
  text-align: left;
  padding: 12px 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.steps-action {
  text-align: right;
}
</style>